// React 🌌
import React, { useState, useEffect } from 'react';
// ⛽️ Bootstrap
import { Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🔨 Utils Fields
import { API_URL, TABLE, USERIMG } from './const';
// 🎭️ Image
import defaultImg from '../assets/img/defaultImgS.png';

/**
 * Utils all reusable function
 *
 * setActive
 * setForEach
 * useFocus
 * sortCaret
 *
 * @augments {Component<Props, State>}
 */

// * FORM Login Register
// Focus input change parent not-empty class
export const setActive = (el, active) => {
  const formField = el.parentNode;
  if (active) {
    formField.classList.add('actives');
  } else {
    formField.classList.remove('actives');
    el.value === '' ? formField.classList.remove('not-empty') : formField.classList.add('not-empty');
  }
};

// Focus input change active
export const setForEach = () => {
  [].forEach.call(document.querySelectorAll('.er-form input'), (el) => {
    el.onblur = () => {
      setActive(el, false);
    };
    el.onFocus = () => {
      setActive(el, true);
    };
  });
};

// Focus input change label class
export const useFocus = (ref, defaultState = false) => {
  const [aktivno, setAktivno] = useState(defaultState);

  useEffect(() => {
    const onFocus = () => setAktivno(true);
    const onBlur = () => setAktivno(false);

    let inputRef = ref.current;

    inputRef.addEventListener('focus', onFocus);
    inputRef.addEventListener('blur', onBlur);

    return () => {
      inputRef.removeEventListener('focus', onFocus);
      inputRef.removeEventListener('blur', onBlur);
    };
  }, [ref]);

  return aktivno;
};

// * SIDEBAR RIGHT
export const handleToggleSidebarRight = () => {
  document.querySelector('.sidebar-right-toggle').classList.toggle('show');
};

// * BOOTSTRAP TABLE
// Custom Caret sorting for BootstrapTable
export const sortCaret = (order, column) => {
  if (!order)
    return (
      <span>
        <FontAwesomeIcon icon={['fad', 'sort']} fixedWidth />
      </span>
    );
  else if (order === 'asc')
    return (
      <span>
        <font color="white">
          <FontAwesomeIcon icon={['fad', 'sort-down']} fixedWidth />
        </font>
      </span>
    );
  else if (order === 'desc')
    return (
      <span>
        <font color="white">
          <FontAwesomeIcon icon={['fad', 'sort-up']} fixedWidth />
        </font>
      </span>
    );
  return null;
};

// Image Formatter for BootstrapTable -- (USER image)
export const imageFormatter = (cell, row) => {
  let image = row.image;
  let linkImage;
  if (image === null) {
    linkImage = defaultImg;
  } else {
    linkImage = API_URL + TABLE + USERIMG + row.usr_id;
  }
  return (
    <div className="photo">
      <img alt="..." src={linkImage} className="float-left" />
    </div>
  );
};

// Custom Search for BootstrapTable
export const SearchItem = (props) => {
  let input;
  const handleSearch = (n) => {
    n.preventDefault();
    props.onSearch(input.value);
  };

  return (
    <div className="d-flex justifay-content-around mb-3">
      <input className="form-control" ref={(n) => (input = n)} type="text" placeholder="Search" id="searchBox" />
      <Button variant="link" className="m-0" onClick={handleSearch}>
        <FontAwesomeIcon icon={['fad', 'search']} size={'lg'} />
      </Button>
    </div>
  );
};

// Custom Search for BootstrapTable Server Side
export const SearchItemServerSide = (props) => {
  let input;
  const handleSearch = (n) => {
    n.preventDefault();
    props.onSearch(input.value);
    props.searchWhere(input.value);
    console.log('SearchItemServerSide_props', props);
  };

  return (
    <div className="d-flex justifay-content-around mb-3">
      <input className="form-control" ref={(n) => (input = n)} type="text" placeholder="Search" id="searchBox" />
      <Button variant="link" className="m-0" onClick={handleSearch}>
        <FontAwesomeIcon icon={['fad', 'search']} size={'lg'} />
      </Button>
    </div>
  );
};

// Custom Clear Button for BootstrapTable
export const ClearButton = (props) => {
  const handleClear = () => {
    props.onSearch('');
    props.clearWhere('');
    document.getElementById('searchBox').value = '';
    console.log('ClearButton_props', props);
  };
  return (
    <Button variant="link" onClick={handleClear} className="m-0">
      <FontAwesomeIcon icon={['fad', 'backspace']} size={'lg'} />
    </Button>
  );
};
