// 🌌 React
import React, { useState, useEffect } from 'react';
// ⛽️ Bootstrap
import { Container, Row, Col, Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🔍 Bootstrap Table 2 and options for table
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
// 🔐 Authentication
//import { UserContext } from '../context/UserContext';
// 🔗 API calls
import { getTable } from '../utils/apiCallCrud';
import { SearchItem, ClearButton, sortCaret, handleToggleSidebarRight } from '../utils/utils';
// 📦 Components
import Loader from '../components/Loading/Loader';
import SidebarRight from '../components/Sidebar/SidebarRight';
// 🍪 Cookies
import Cookies from 'js-cookie';

/**
 * StoresTable View
 *
 * @augments {Component<Props, State>}
 */

const StoresTable = ({ history }) => {
  // * Authentication user
  // replaced user with token
  // const { user } = useContext(UserContext);

  useEffect(() => {
    let token = Cookies.get('SID');
    if (!token) {
      history.push('/login');
    }
  }, [history]);

  // * Loading
  const [loading, setLoading] = useState(false);

  // * Main Data
  const [tableData, setTableData] = useState([]);

  // * MAIN DATA CALL
  useEffect(() => {
    let isClean = true;
    setLoading(true);
    setTimeout(() => {
      getTable('stores').then((data) => {
        if (isClean) {
          setTableData(data);
          setLoading(false);
          return;
        }
      });
    }, 500);

    return () => (isClean = false);
  }, []);

  // * TABLE COLUMN and OPTIONS SETTINS
  const defaultSorted = [
    {
      dataField: 'store_id',
      order: 'asc',
    },
  ];

  const options = {
    nextPageTitle: 'Next page',
    prePageTitle: 'Previous page',
    firstPageTitle: 'First page',
    lastPageTitle: 'Last page',
    showTotal: false,
    disablePageTitle: false,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: 'All',
        value: tableData.length,
      },
    ],
  };

  const columns = [
    {
      dataField: 'store_id',
      text: 'ID',
      sort: true,
      sortCaret: sortCaret,
      headerStyle: () => {
        return { minWidth: '48px' };
      },
    },
    {
      dataField: 'store_name',
      text: 'Name',
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: 'store_retail',
      text: 'Retail',
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: 'store_address',
      text: 'Address',
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  return (
    <>
      <SidebarRight title={'Add New Store'}>
        <p>CHILD ZA STORE</p>
      </SidebarRight>
      <Container fluid className="content">
        <Row>
          <ToolkitProvider bootstrap4 keyField="store_id" data={tableData} columns={columns} search>
            {(props) => (
              <Col xs="12">
                <Row className="mb-3 mb-sm-1">
                  <Col xs="12" sm="8">
                    <Row>
                      <Col xs="10" lg="11" className="pr-0">
                        <SearchItem {...props.searchProps} />
                      </Col>
                      <Col xs="2" lg="1" className="px-0">
                        <ClearButton {...props.searchProps} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" sm="4" className="pl-lg-0">
                    <Button variant="link" className="m-0" onClick={handleToggleSidebarRight}>
                      <FontAwesomeIcon icon={['far', 'plus-hexagon']} size={'lg'} /> Add Store
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    {loading === false ? (
                      <BootstrapTable
                        {...props.baseProps}
                        keyField="store_id"
                        data={tableData}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        wrapperClasses="table-responsive"
                        hover={true}
                        bordered={false}
                        pagination={paginationFactory(options)}
                        noDataIndication="Table is Empty"
                      />
                    ) : (
                      <Loader />
                    )}
                  </Col>
                </Row>
              </Col>
            )}
          </ToolkitProvider>
        </Row>
      </Container>
    </>
  );
};

export default StoresTable;
