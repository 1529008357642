// 🌌 React
import React, { useState, useEffect } from 'react';
// ⛽️ Bootstrap
import { Container, Row, Col, Badge } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🔗 API calls
import { getTable } from '../utils/apiCallCrud';
// 🍪 Cookies
import Cookies from 'js-cookie';
// ⏱ TimeAgo
import TimeAgo from 'react-timeago';
import bosnianStrings from 'react-timeago/lib/language-strings/bs';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
// 📦 Components
//import Loader from '../components/Loading/Loader';
// 🔡 DamyData
// import datad from '../variables/postdamy.json';

/**
 * Dashboard View
 *
 * @augments {Component<Props, State>}
 */

const Dashboard = ({ history }) => {
  // * Authentication user
  // replaced user with token
  // const { user } = useContext(UserContext);

  useEffect(() => {
    let token = Cookies.get('SID');
    if (!token) {
      history.push('/login');
    }
  }, [history]);

  // * Loading
  const [loading, setLoading] = useState(false);

  // * Main Data
  const [tableData, setTableData] = useState([]);

  // * MAIN DATA CALL
  useEffect(() => {
    let isClean = true;
    setLoading(true);
    setTimeout(() => {
      getTable('news').then((data) => {
        if (isClean) {
          setTableData(data);
          setLoading(false);
          return;
        }
      });
    }, 500);

    return () => (isClean = false);
  }, []);

  const formatter = buildFormatter(bosnianStrings);

  const NewsTimeline = () => {
    return (
      <ul className="timeline timeline-simple">
        {tableData.map((news) => {
          let variant;
          switch (news.news_status) {
            case 1:
              variant = 'info';
              break;
            case 2:
              variant = 'success';
              break;
            case 3:
              variant = 'danger';
              break;
            default:
              variant = 'info';
          }
          return (
            <li key={news.news_id} className="timeline-inverted">
              <div className={`timeline-badge ${variant}`}>
                <div className="d-flex flex-column">
                  <span className="small first">
                    {news.news_date.substring(8, 10)}.{news.news_date.substring(5, 7)}.
                  </span>
                  <span className="small">{news.news_date.substring(0, 4)}</span>
                </div>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <Badge variant={variant}>{news.news_title}</Badge>
                </div>
                <div className="timeline-body">
                  <p>{news.news_body}</p>
                </div>
                <hr />
                <h6>
                  <FontAwesomeIcon icon={['fad', 'stopwatch']} size={'lg'} className="mr-2" />
                  <TimeAgo date={news.news_date} formatter={formatter} />
                </h6>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <Container fluid className="content">
      <Row>
        <Col xs="12" sm="12" lg="12">
          <p>Dashboard content here</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
