// 🌌 React
import React from 'react';
// 👍 IconDoc componnent
import IconDoc from '../IconDoc/IconDoc';

/**
 * Copyright Component/Footer
 *
 * @augments {Component<Props, State>}
 */

const Copyright = () => {
  return (
    <div className="copyright">
      <IconDoc category="footerIcon" />
    </div>
  );
};

export default Copyright;
