// 🌌 React
import React, { useState, useEffect } from 'react';
// ⛽️ Bootstrap
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🌍 Translations
import { useTranslation } from 'react-i18next';
// 🔗 API calls
import { onEditUser, getUser, getUserFullname } from '../utils/apiCallCrud';
// 🔨 Utils Fields
import { API_URL, TABLE, USERIMG, fieldUser } from '../utils/const';
// 📦 Components
import Loader from '../components/Loading/Loader';
// 📢 Sweet Alert 2 like toast massage
import { ToastTop } from '../components/SwalModalToast/SwalModalToast';
// 🎭️ Image
import defaultImg from '../assets/img/defaultImgT.png';
// 🍪 Cookies
import Cookies from 'js-cookie';

/**
 * UserProfile View and Edit
 *
 * @augments {Component<Props, State>}
 */

const UserProfile = ({ history }) => {
  // * Translations
  const { t } = useTranslation();

  // * Authentication user
  // replaced user with token
  // const { user } = useContext(UserContext);

  useEffect(() => {
    let token = Cookies.get('SID');
    if (!token) {
      history.push('/login');
    }
  }, [history]);

  let userId = Cookies.get('ID');

  // * Loading
  const [loading, setLoading] = useState(false);

  // * Validating form state Bootstrap
  const [validated, setValidated] = useState(false);
  const [disabled, setDisabled] = useState(true);

  // * Main Data
  const [dataInit, setDataInit] = useState(fieldUser);
  const [dataEdit, setDataEdit] = useState(fieldUser);

  // * First name for title belowe image
  const [firstName, setFirstName] = useState('');

  // * MAIN DATA CALL
  useEffect(() => {
    let isClean = true;
    setLoading(true);

    setTimeout(() => {
      const data = async () => {
        if (isClean) {
          if (userId) {
            const response = await getUser(userId);
            let fullNameInit = response[0].usr_fullname;
            setFirstName(fullNameInit.split(' ')[0]);
            setDataInit(response);
            setDataEdit(response);
            setLoading(false);
          }
        }
      };
      data();
    }, 500);

    return () => (isClean = false);
  }, [userId]);

  // * DEFAULT IMAGE
  let linkImage;
  const getImage = () => {
    if (userId) {
      let image = dataInit.image;
      if (image === null) {
        linkImage = defaultImg;
      } else {
        linkImage = API_URL + TABLE + USERIMG + userId;
      }
    }
  };
  getImage();

  // * DATA compare change input with init
  useEffect(() => {
    // * JSON stringify
    if (JSON.stringify(dataEdit) === JSON.stringify(dataInit)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [dataEdit, dataInit]);

  // * HANDLE RESULTS
  const handleResult = async (results) => {
    let { status, error } = results;
    if (error) {
      ToastTop.fire({
        icon: 'error',
        title: t(error),
      });
      return;
    }
    if (status === 200) {
      ToastTop.fire({
        icon: 'success',
        title: t('swal.createedit.success.title'),
      });

      setTimeout(() => {
        setValidated(false);
        setDisabled(true);
      }, 2500);
    }
  };

  // * HANDLE SUBMIT edit
  const handleSubmitEdit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      event.preventDefault();
      onEditUser(dataEdit).then((data) => {
        handleResult(data);
      });
    }
  };

  // * HANDLE CHANGE input
  const handleOnChange = (e) => {
    setDataEdit({
      ...dataEdit,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container fluid className="content">
      {loading === false ? (
        <Row>
          <Col md="8">
            <Card>
              <Form noValidate validated={validated} onSubmit={handleSubmitEdit} autoComplete="off">
                <Card.Header>
                  <h4>{t('crud.title.edit')} Profile</h4>
                </Card.Header>
                <Card.Body>
                  <Form.Row>
                    <Form.Group as={Col} md={6} className="pr-md-1" controlId="usr_organisation">
                      <Form.Label>Company (disabled)</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        name="usr_organisation"
                        placeholder="Organisation"
                        value="DOC.ba"
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Form.Group>
                    {/* <Form.Group as={Col} md={3} className="px-md-1" controlId="usr_username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      name="usr_email"
                      placeholder="Username"
                      value={dataEdit.usr_email}
                      onChange={(e) => {
                        handleOnChange(e);
                      }}
                    />
                  </Form.Group> */}
                    <Form.Group as={Col} md={6} className="pl-md-1" controlId="usr_username">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        name="usr_username"
                        placeholder="Your email"
                        value={dataEdit.usr_username}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={12} controlId="usr_fullname">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="usr_fullname"
                        value={dataEdit.usr_fullname}
                        placeholder="Full Name"
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={12} controlId="usr_address">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="usr_address"
                        value={dataEdit.usr_address}
                        placeholder="Home Address"
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={4} className="pr-md-1" controlId="usr_city">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="usr_city"
                        value={dataEdit.usr_city}
                        placeholder="City"
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={4} className="px-md-1" controlId="usr_country">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="usr_country"
                        value={dataEdit.usr_country}
                        placeholder="Country"
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={4} className="pl-md-1" controlId="usr_postalcode">
                      <Form.Label>Postal Code</Form.Label>
                      <Form.Control
                        type="number"
                        name="usr_postalcode"
                        value={dataEdit.usr_country}
                        placeholder="ZIP Code"
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={12} controlId="usr_desc">
                      <Form.Label>About Me</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        name="usr_desc"
                        placeholder="Here can be your description"
                        rows={4}
                        value={dataEdit.usr_desc || ''}
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      />
                    </Form.Group>
                  </Form.Row>
                </Card.Body>
                <Card.Footer>
                  <Button variant="primary" type="submit" disabled={disabled} className="btn-fill">
                    {t('table.btn.save')}
                    <FontAwesomeIcon icon={['fad', 'save']} className="ml-2" />
                  </Button>
                </Card.Footer>
              </Form>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <Card.Body>
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#erodin" onClick={(e) => e.preventDefault()}>
                    <img alt="..." className="avatar mx-auto d-block" src={linkImage} />
                    <h5 className="title">{firstName}</h5>
                  </a>
                  <p className="description">Senior Developer</p>
                </div>
                <div className="card-description">
                  One of the major reasons we give up on something is because we are afraid of not having what it takes. You sit in front of your black page and
                  doubts start filling your mind before you can even put your first word down. You doubt your skills. You doubt your feelings and opinions. You
                  doubt your truth.
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default UserProfile;
