// 🔌 Axios
import axios from 'axios';
// 🔨 Const Utils
import { API_URL, LOGIN, REGISTER } from './const';
import { handleError } from './handleRequestError';
import { getUser } from './apiCallCrud';

/**
 * GET, POST, UPDATE, DELETE
 *
 * @augments {Component<Props>}
 */

// * Authorize role

export async function authRole(id, role) {
  return await getUser(id).then((user) => {
    if (user[0].usr_role !== role) {
      return 'false';
    } else {
      return 'true';
    }
  });
}

// * Login User
export async function loginUser(username, password) {
  return await axios
    .post(`${API_URL}${LOGIN}`, {
      username,
      password,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return handleError(err.response);
    });
}

// * Users Me
// export async function getUsersMe() {
//   var token = Cookies.get('jwt');

//   return await axios
//     .get(`${API_URL}${USERSME}`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       return handleError(err.response);
//     });
// }

// * Register User
export async function registerUser(username, password, fullname) {
  return await axios
    .post(`${API_URL}${REGISTER}`, {
      username,
      password,
      fullname,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return handleError(err.response);
    });

  // var rawData = await fetch(`${API_URL}${REGISTER}`, {
  //   method: 'POST',
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-type': 'application/json',
  //   },
  //   body: JSON.stringify({ email, password, username }),
  // });
  // var data = await rawData.json();
  // return data;
}

// * Forgotten password
// export async function forgotPassword(email) {
//   return await axios
//     .post(`${API_URL}${FORGOTPASSWORD}`, {
//       email,
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       return handleError(err.response);
//     });
// }

// * Reset Password
// export async function resetPassword(code, password, passwordConfirmation) {
//   return await axios
//     .post(`${API_URL}${RESETPASSWORD}`, {
//       code,
//       password,
//       passwordConfirmation,
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       return handleError(err.response);
//     });
// }

// * Email Confirmation (ako je upaljena opcija u strapiju za email verifikaciju)
// export async function emailConfirmation(email) {
//   return await axios
//     .post(`${API_URL}${EMAILCONFIRM}`, {
//       email,
//     })
//     .then((response) => {
//       return response.data;
//     })
//     .catch((err) => {
//       return handleError(err.response);
//     });
// }
