// 🌌 React
import React, { useState, useEffect } from 'react';
// ⛽️ Bootstrap
import { Container, Row, Col, Dropdown, Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🌍 Translations
import { useTranslation } from 'react-i18next';
// 🔍 Bootstrap Table 2 and options for table
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
// 🔗 API calls
import { getTableP, getTotalSize } from '../utils/apiCallCrud';
// 🔨 Const Utils
import { SearchItemServerSide, ClearButton, sortCaret, handleToggleSidebarRight } from '../utils/utils';
// 📦 Components
import Loader from '../components/Loading/Loader';
import SidebarRight from '../components/Sidebar/SidebarRight';
// 🍪 Cookies
import Cookies from 'js-cookie';

/**
 * ItemsTable View
 *
 * @augments {Component<Props, State>}
 */

const ItemsTable = ({ history }) => {
  // * Table for API
  const table = 'items';

  // * Translations
  const { t } = useTranslation();

  // * Authentication user
  useEffect(() => {
    let token = Cookies.get('SID');
    if (!token) {
      history.push('/login');
    }
  }, [history]);

  // * Loading
  const [loading, setLoading] = useState(false);

  // * Pagination
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  // * Sorting
  const [sorting, setSorting] = useState({
    key: 'items_id',
    order: 'asc',
  });

  // * Search
  const [where, setWhere] = useState(1);

  // * Main Data
  const [tableData, setTableData] = useState([]);

  // * MAIN DATA CALL & COUNT data for total size
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const data = async () => {
        // table data
        const response = await getTableP(table, start, limit, sorting, where);
        setTableData(response);

        // total size data
        const responseSize = await getTotalSize(table, where);
        setTotalSize(responseSize);

        setLoading(false);
      };
      data();
    }, 0);
  }, [start, limit, sorting, where]);

  // * TABLE COLUMN, OPTIONS and CUSTOM SETTINS
  // Custom Select for BootstrapTable
  // options as Object
  const selectOptions = {
    1: 'Prod.1',
    2: 'Prod.2',
  };
  // Set initial variables
  let qualityFilter;
  let defaultValueSelect = Object.keys(selectOptions).map((o) => [Number(o), selectOptions[o]]);

  // options as Array OSTAVIO AKO BUDEM DOBIVAO PODATKE IZ MARSA na drugi nacin
  // const selectOptionsArr = [
  //   { value: '', label: 'All' },
  //   { value: 1, label: 'Prod.1' },
  //   { value: 2, label: 'Prod.2' },
  // ];

  const SelectDropdownByColumns = () => {
    const [selectActive, setSelectActive] = useState(defaultValueSelect[0][1]);
    return (
      <Dropdown>
        <Dropdown.Toggle block variant="secondary" id="select-store" className="m-0 px-0">
          Select Store - {selectActive}
        </Dropdown.Toggle>
        <Dropdown.Menu align="right">
          <Dropdown.Item
            onSelect={() => {
              qualityFilter('');
              setSelectActive('All');
            }}
          >
            All
          </Dropdown.Item>
          {Object.keys(selectOptions).map((o, i) => (
            <Dropdown.Item
              key={i}
              onSelect={() => {
                qualityFilter(Number(o));
                setSelectActive(selectOptions[o]);
              }}
            >
              {selectOptions[o]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // Icon Status
  // const iconStatusFormatter = (cell, row, rowIndex) => {
  //   let proc = Math.round((row.item_current_qty / row.item_max_qty) * 100);
  //   let show = 'd-none';
  //   let variants;
  //   if (proc <= 10) {
  //     variants = 'danger';
  //     show = 'd-flex';
  //   } else if (proc > 10 && proc <= 40) {
  //     variants = 'warning';
  //   } else if (proc > 40 && proc <= 50) {
  //     variants = 'info';
  //   } else if (proc > 50) {
  //     variants = 'success';
  //   }

  //   return (
  //     <div className="d-flex justify-content-start">
  //       <Badge pill variant={variants} className="m-0">
  //         {proc}%
  //       </Badge>
  //       <Spinner className={show} animation="grow" style={{ width: '0.5rem', height: '0.5rem', color: '#ff0c3c' }} />
  //     </div>
  //   );
  // };

  // * SIZE PER PAGE options
  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item" style={{ padding: '0.25rem 0.25rem' }}>
      <button
        className="btn btn-link btn-block btn-sm text-decoration-none"
        role="menuitem"
        data-page={page}
        onMouseDown={(e) => {
          e.preventDefault();
          onSizePerPageChange(page);
        }}
        style={{ color: 'rgba(0, 152, 218, 1)', padding: '0 0.25rem' }}
      >
        {text}
      </button>
    </li>
  );

  // * SHOWING RESULTS text
  const customTotal = (from, to, size) => {
    if (paginationOptions.showTotal) {
      console.log('customTotal ', 'from', from, 'to', to, 'size', size);
      return (
        <span className="react-bootstrap-table-pagination-total small">
          {t('table.pagination.showing')} {from} {t('table.pagination.to')} {to} {t('table.pagination.of')} {size} {t('table.pagination.results')}
        </span>
      );
    } else {
      return null;
    }
  };

  // * HANDLE TABLE CHANGE
  const handleTableChange = (type, { page, sizePerPage }) => {
    console.log('handleTableChange ', 'page', page, 'sizePerPAge', sizePerPage);
    setStart((page - 1) * sizePerPage);
    setLimit(sizePerPage);
  };

  // * HANDLE SORTING
  const handleSort = (field, order) => {
    setSorting({
      key: field,
      order: order,
    });
  };

  const defaultSorted = [
    {
      dataField: 'item_id',
      order: 'asc',
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalSize,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '15',
        value: 15,
      },
      {
        text: '20',
        value: 20,
      },
      {
        text: '50',
        value: 50,
      },
    ],
    paginationSize: 3, // The pagination bar size, default is 5
    alwaysShowAllBtns: true, // The pagination bar button show all
    // withFirstAndLast: false, // Hide the going to First and Last page button
    hideSizePerPage: totalSize === 0, // Hide the sizePerPage dropdown
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,

    nextPageTitle: t('table.pagination.nextPageTitle'),
    prePageTitle: t('table.pagination.prePageTitle'),
    firstPageTitle: t('table.pagination.firstPageTitle'),
    lastPageTitle: t('table.pagination.lastPageTitle'),

    disablePageTitle: true, // Hide title on hover for pagination
  };

  const columns = [
    {
      dataField: 'item_id',
      text: 'ID',
      sort: true,
      searchable: true,
      sortCaret: sortCaret,
      onSort: handleSort,
      // filter: textFilter(),
      headerStyle: () => {
        return { minWidth: '48px' };
      },
    },
    // {
    //   dataField: 'icon_status',
    //   isDummyField: true,
    //   text: 'Status',
    //   sort: true,
    //   formatter: iconStatusFormatter,
    //   searchable: true,
    //   sortCaret: sortCaret,
    //   onSort: handleSort,
    //   filter: textFilter(),
    // },
    {
      dataField: 'item_name',
      text: 'Name',
      sort: true,
      searchable: true,
      sortCaret: sortCaret,
      onSort: handleSort,
      // filter: textFilter(),
    },
    {
      dataField: 'item_exp_date',
      text: 'Exp.date',
      sort: true,
      searchable: true,
      sortCaret: sortCaret,
      onSort: handleSort,
      // filter: textFilter(),
    },

    // {
    //   dataField: 'item_store',
    //   text: 'Store',
    //   sort: true,
    //   searchable: false,
    //   sortCaret: sortCaret,
    //   onSort: handleSort,
    //   formatter: (cell) => selectOptions[cell],
    //   filter: selectFilter({
    //     options: selectOptions,
    //     defaultValue: defaultValueSelect[0][0],
    //     getFilter: (filter) => {
    //       // qualityFilter was assigned once the component has been mounted.
    //       qualityFilter = filter;
    //     },
    //     // onFilter: (filterVal) => console.log(`Filter Value: ${filterVal}`),
    //     // Ovaj dole display none je da se sakrije u tabelama select jer je napravljen kao dropdown iznad tabela
    //     style: { display: 'none' },
    //   }),
    // },

    // {
    //   dataField: 'item_current_qty',
    //   text: 'Qty',
    //   sort: true,
    //   searchable: true,
    //   sortCaret: sortCaret,
    //   onSort: handleSort,
    //   filter: textFilter(),
    // },
    // {
    //   dataField: 'item_max_qty',
    //   text: 'Max Qty',
    //   sort: true,
    //   searchable: true,
    //   sortCaret: sortCaret,
    //   onSort: handleSort,
    //   filter: textFilter(),
    // },
    {
      dataField: 'item_unit',
      text: 'Unit',
      sort: true,
      searchable: true,
      sortCaret: sortCaret,
      onSort: handleSort,
      // filter: textFilter(),
    },
    {
      dataField: 'item_wholesale',
      text: 'Wholesale',
      sort: true,
      searchable: true,
      sortCaret: sortCaret,
      onSort: handleSort,
      // filter: textFilter(),
    },
  ];

  return (
    <>
      <SidebarRight title={'Add New Items'}></SidebarRight>
      <Container fluid className="content">
        <Row>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider bootstrap4 keyField="item_id" columns={columns} data={tableData} search>
                {(toolkitprops) => (
                  <Col xs="12">
                    <Row className="mb-3 mb-sm-1">
                      <Col xs="12" sm="7" className="pr-sm-0">
                        <Row>
                          <Col xs="10" className="pr-sm-0">
                            <SearchItemServerSide {...toolkitprops.searchProps} searchWhere={setWhere} />
                          </Col>
                          <Col xs="2" className="pl-sm-0">
                            <ClearButton {...toolkitprops.searchProps} clearWhere={setWhere} />
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" sm="5" className="pl-sm-0">
                        <Row>
                          <Col xs="6" sm="4" className="px-sm-0">
                            <Button variant="link" className="m-0" onClick={handleToggleSidebarRight}>
                              <FontAwesomeIcon icon={['far', 'plus-hexagon']} size={'lg'} /> Add Items
                            </Button>
                          </Col>
                          <Col xs="6" sm="8" className="pl-sm-0">
                            <SelectDropdownByColumns />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        {loading === false ? '' : <Loader />}
                        <BootstrapTable
                          {...toolkitprops.baseProps}
                          {...paginationTableProps}
                          remote
                          keyField="item_id"
                          data={tableData}
                          columns={columns}
                          defaultSorted={defaultSorted}
                          wrapperClasses={`table-responsive ${loading ? 'loading' : ''}`}
                          hover
                          bordered={false}
                          // page={start}
                          sizePerPage={limit}
                          totalSize={totalSize}
                          onTableChange={handleTableChange}
                          // filter={filterFactory()}
                          noDataIndication={'No data Found'}
                        />
                        <Row>
                          <Col xs={12} sm={6}>
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                              sizePerPageOptionRenderer={sizePerPageOptionRenderer}
                              btnContextual="py-2 btn-secondary mr-3"
                            />
                            <PaginationTotalStandalone {...paginationProps} paginationTotalRenderer={customTotal} />
                          </Col>
                          <Col xs={12} sm={6} className="small mb-0 mt-3 mt-sm-0 d-flex justify-content-center justify-content-md-end  align-items-center">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Row>
      </Container>
    </>
  );
};

export default ItemsTable;
