// 🌌 React
import React, { useState, useEffect } from 'react';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🔨 Const Utils
import { getOption } from '../../utils/apiCallCrud';
import { iconStart } from '../../utils/const';

/**
 * IconDoc Component/Footer
 *
 * @augments {Component<Props, State>}
 */

const IconDoc = ({ category }) => {
  const [optData, setOptData] = useState(iconStart);

  useEffect(() => {
    let effectCleanup = true;
    getOption(category).then((data) => {
      if (effectCleanup) {
        setOptData(data);
        return;
      }
    });
    return () => (effectCleanup = false);
  }, [category]);

  return <FontAwesomeIcon icon={[optData.prefix, optData.iconName]} size={optData.size} color={optData.color} />;
};

export default IconDoc;
