// 🌌 React
import React, { useState, useEffect, useRef, useContext } from 'react';
// 🚧 React router
import { NavLink } from 'react-router-dom';
// ⛽️ Bootstrap
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🌍 Translations
import { useTranslation } from 'react-i18next';
// 🔐 Authentication
import { UserContext } from '../context/UserContext';
// 🍪 Cookies
import Cookies from 'js-cookie';
// 🔗 API calls
import { registerUser } from '../utils/apiCallAuth';
// 🔨 Utils
import { setForEach, useFocus } from '../utils/utils';
// 📢 Sweet Alert 2 like toast massage
import { ToastTop } from '../components/SwalModalToast/SwalModalToast';
// ⚙️ Settings for Theme Color and Sidebar
import FixedPlugin from '../components/FixedPlugin/FixedPlugin';

/**
 * Register Layout
 *
 * @augments {Component<Props, State>}
 */

const Register = (props, { history }) => {
  // * Translations
  const { t } = useTranslation();
  // * Authentication user state
  const { user, setUser } = useContext(UserContext);
  useEffect(() => {
    if (user) {
      props.history.push('/admin/dashboard');
    }
  }, [user, props.history]);

  // * Focus input change active
  useEffect(() => {
    setForEach();
  }, []);

  // * Validating form state Bootstrap
  const [validated, setValidated] = useState(false);

  // * UserName / Email / Password / ConfirmPassword
  const [credencials, setCredencials] = useState({
    username: '',
    email: '',
    password: '',
    confirmpassword: '',
  });

  // * Seting class for icons and fields validation for match Password with ConfirmPassword
  const [validClass, setValidClass] = useState('');
  const [validIcons, setValidIcons] = useState('lock-open-alt');

  // * API CALLS for Register User
  const handleResult = async (result) => {
    let { sid, user, error } = result;
    if (error) {
      ToastTop.fire({
        icon: 'error',
        title: t(error),
      });
      return;
    }
    if (user && sid) {
      let inMinutes = new Date(new Date().getTime() + 45 * 60 * 1000); // Change 45 for other expire time in minutes
      Cookies.set('SID', sid, { expires: inMinutes });
      Cookies.set('ID', user.usr_id, { expires: inMinutes });
      setUser(sid, history);
      ToastTop.fire({
        icon: 'success',
        title: t('swal.register.success'),
      });
    }
  };

  // * PASSWORD MATCH Confirm-Password
  const onVerifyConfirmPassword = () => {
    if (credencials.password.length > 0 && credencials.confirmpassword.length > 0) {
      if (credencials.password !== credencials.confirmpassword) {
        setValidClass('er-invalid');
        setValidIcons('lock-open-alt');
        return;
      } else {
        setValidClass('er-valid');
        setValidIcons('lock-alt');
        return;
      }
    }
  };

  // * HANDLE SUBMIT for Register User
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      // Postavljena ovdje kontrola da ne prodje ako se ne poklapaju pass - nema u defaultnoj checkValidity
      if (credencials.password !== credencials.confirmpassword) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        event.preventDefault();
        registerUser(credencials.email, credencials.password, credencials.username).then((data) => {
          handleResult(data);
        });
      }
    }
  };

  // * HANDLE CHANGE input
  const handleOnchange = (e) => {
    setCredencials({
      ...credencials,
      [e.target.id]: e.target.value,
    });
  };

  // * USEREF & USEFOCUS CHANGE input
  const refusername = useRef();
  const focusedusername = useFocus(refusername);
  const refemail = useRef();
  const focusedemail = useFocus(refemail);
  const refpass = useRef();
  const focusedpass = useFocus(refpass);
  const refconfpass = useRef();
  const focusedconfpass = useFocus(refconfpass);

  return (
    <Container className="page mt-5 pt-3 animate__animated animate__zoomIn">
      <Row className="d-flex justify-content-center">
        <Col xs={'11'} sm={'9'} md={'7'} lg={'5'} xl={'4'} className="d-flex justify-content-center px-0 py-3">
          <Card className="mt-4 mb-5 w-100">
            <Card.Body>
              <Card.Header className="er-header er-gradient rounded">
                <h3 className="my-3">
                  <FontAwesomeIcon icon={['fad', 'users-medical']} size={'1x'} color={'#ffffff'} /> {t('register.title')}
                </h3>
              </Card.Header>
              <Form noValidate validated={validated} onSubmit={handleSubmit} className="mx-2 mt-5">
                <Form.Group controlId="username" className="er-form">
                  <Form.Control
                    required
                    type="text"
                    ref={refusername}
                    value={credencials.username}
                    name="username"
                    className="er-input-ml-w"
                    onChange={(e) => {
                      handleOnchange(e);
                    }}
                  />
                  <Form.Label className={`${focusedusername && 'active'}`}>{t('register.user')}</Form.Label>
                  <Form.Control.Feedback type="invalid">{t('register.validusername')}</Form.Control.Feedback>
                  <FontAwesomeIcon icon={['fad', 'user']} color={'#6c757d'} className={`prefix ${focusedusername && 'active'}`} />
                </Form.Group>

                <Form.Group controlId="email" className="er-form">
                  <Form.Control
                    required
                    type="email"
                    ref={refemail}
                    value={credencials.email}
                    name="email"
                    className="er-input-ml-w"
                    onChange={(e) => {
                      handleOnchange(e);
                    }}
                  />
                  <Form.Label className={`${focusedemail && 'active'}`}>{t('loginregister.email')}</Form.Label>
                  <Form.Control.Feedback type="invalid">{t('loginregister.validmail')}</Form.Control.Feedback>
                  <Form.Text className={`${focusedemail && 'active'} text-muted er-input-ml-w`}>{t('loginregister.neversharemail')}</Form.Text>
                  <FontAwesomeIcon icon={['fad', 'envelope']} color={'#6c757d'} className={`prefix ${focusedemail && 'active'}`} />
                </Form.Group>

                <Form.Group controlId="password" className="er-form">
                  <Form.Control
                    required
                    type="password"
                    ref={refpass}
                    value={credencials.password}
                    name="password"
                    className="er-input-ml-w"
                    minLength={6}
                    maxLength={25}
                    pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$"
                    onChange={(e) => {
                      handleOnchange(e);
                      onVerifyConfirmPassword(e);
                    }}
                    onBlur={onVerifyConfirmPassword}
                    onKeyUp={onVerifyConfirmPassword}
                  />
                  <Form.Label className={`${focusedpass && 'active'}`}>{t('loginregister.password')}</Form.Label>
                  <Form.Control.Feedback type="invalid">{t('register.invalidpassword')}</Form.Control.Feedback>
                  <FontAwesomeIcon icon={['fad', 'key']} color={'#6c757d'} className={`prefix ${focusedpass && 'active'}`} />
                </Form.Group>

                <Form.Group controlId="confirmpassword" className="er-form">
                  <Form.Control
                    required
                    type="password"
                    ref={refconfpass}
                    value={credencials.confpassword}
                    name="confirmpassword"
                    className={`${validClass} er-input-ml-w`}
                    onChange={(e) => {
                      handleOnchange(e);
                      onVerifyConfirmPassword(e);
                    }}
                    onBlur={onVerifyConfirmPassword}
                    onKeyUp={onVerifyConfirmPassword}
                  />
                  <Form.Label className={`${focusedconfpass && 'active'}`}>{t('register.confirmpassword')}</Form.Label>
                  <Form.Control.Feedback type="invalid">{t('register.invalidconfirm')}</Form.Control.Feedback>
                  <Form.Control.Feedback type="valid" className={validClass}>
                    {t('register.invalidconfirm')}
                  </Form.Control.Feedback>
                  <FontAwesomeIcon icon={['fad', `${validIcons}`]} color={'#6c757d'} className={`prefix ${focusedconfpass && 'active'}`} />
                </Form.Group>
                <Button block variant="primary" type="submit" className="rounded-pill text-uppercase btn-login mt-5">
                  {t('loginregister.signup')}
                  <FontAwesomeIcon icon={['fad', 'paper-plane']} size={'1x'} className="ml-2" />
                </Button>

                {/* <Row className="d-flex justify-content-center">
                  <p className="text-center mt-3">
                    <small>{t('register.orsignupwith')}</small>
                  </p>
                </Row>

                <Row className="d-flex justify-content-around">
                  <Button variant="light" className="rounded-pill btn-social">
                    <FontAwesomeIcon icon={['fab', 'facebook-f']} color={'#0098da'} />
                  </Button>
                  <Button variant="light" className="rounded-pill btn-social">
                    <FontAwesomeIcon icon={['fab', 'google-plus-g']} color={'#0098da'} />
                  </Button>
                </Row> */}
              </Form>
              <hr />
              <p className="d-flex justify-content-end align-items-center">
                <small>{t('register.notmemberyet')}</small>
                <NavLink to="/login" href="/login" exact className="nav-link py-0 pr-0 pl-2 er-lineheight">
                  <small className="align-text-bottom">{t('loginregister.login')}</small>
                </NavLink>
              </p>
            </Card.Body>
          </Card>
        </Col>
        <FixedPlugin {...props} />
      </Row>
    </Container>
  );
};

export default Register;
