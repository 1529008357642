/**
 * CONST
 *
 * @augments utils
 */

export const API_URL = 'https://app.doc.ba';

export const LOGIN = '/api/auth/login';
export const REGISTER = '/api/auth/register';

export const TABLE = '/api/table/';
export const EDITPROFILE = '/api/auth/editprofile'; //https://app.doc.ba/api/auth/editprofile?usr_id=1&fullname=Nedak
export const USERIMG = 'users/image/';
export const USER = '/api/table/users/';

export const OPTNAME = '/api/opt/get?opt_name=';
export const OPTCATEGORY = '/api/opt/get?opt_category=';

// * ROLES DEFINITION

export const ROLE = {
  ADMIN: 'admin',
  BASIC: 'basic',
};

// * FIELDS DEFINITION
export const fieldUser = {
  usr_id: '',
  usr_username: '',
  usr_fullname: '',
  usr_desc: '',
  image: '',
};

export const iconStart = {
  prefix: 'far',
  iconName: 'heart',
  size: 'lg',
  color: '#00ff00',
};
