// 🌌 React
import React from 'react';
import ReactDOM from 'react-dom';
// 🌍 Translations
import './i18n';
// 🎨️ index css
import './index.css';
// 🚀 App
import App from './App';
// 🔐 Authentication User Context Provider
import UserContextProvider from './context/UserContext';

// TODO iskljucio report: import reportWebVitals from './reportWebVitals';

/**
 * Index
 *
 * @augments {UserContextProvider}
 */

// <React.StrictMode>
ReactDOM.render(
  <UserContextProvider>
    <App />
  </UserContextProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO iskljucio report: reportWebVitals(console.log);
