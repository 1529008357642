// 🌌 React
import React from 'react';
// ⛽️ Bootstrap
import { Container, Nav } from 'react-bootstrap';
// 📦 Components
import Copyright from './Copyright';
import logo from '../../logo.svg';

/**
 * Footer Component
 *
 * @augments {Component<Props, State>}
 */

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <Nav>
          <Nav.Item className="p-0">
            <Nav.Link href="https://doc.ba">
              <img style={{ borderRadius: 0, padding: 0 }} alt="Logo-DOCba" src={logo} width="50" height="auto" />
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Copyright />
      </Container>
    </footer>
  );
};

export default Footer;
