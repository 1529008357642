import Dashboard from './views/Dashboard';
import StoresTable from './views/StoresTable';
import UsersTable from './views/UsersTable';
import ItemsTable from './views/ItemsTable';
import UserProfile from './views/UserProfile';
import ZoomGame from './views/ZoomGame';

/*
 *
 * Routes
 *
 */

var routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fal fa-chart-pie-alt',
    component: Dashboard,
    layout: '/admin',
    tables: 'dash',
  },
  {
    path: '/stores',
    name: 'Stores',
    icon: 'fal fa-cash-register',
    component: StoresTable,
    layout: '/admin',
    tables: 'stores',
  },
  {
    path: '/items',
    name: 'Items',
    icon: 'fal fa-barcode-read',
    component: ItemsTable,
    layout: '/admin',
    tables: 'items',
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'fal fa-user-friends',
    component: UsersTable,
    layout: '/admin',
    tables: 'users',
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    icon: 'fal fa-user',
    component: UserProfile,
    layout: '/admin',
    tables: 'users',
  },
  {
    path: '/zoomgame',
    name: 'Zoom Game',
    icon: 'fal fa-user',
    component: ZoomGame,
    layout: '/admin',
    tables: 'dash',
  },
];

export default routes;
