// 🌌 React
import React, { useState, useEffect } from 'react';
// ⛽️ Bootstrap
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🌍 Translations
import { useTranslation } from 'react-i18next';
// 🔍 Bootstrap Table 2 and options for table
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
// 🔐 Authentication
//import { UserContext } from '../context/UserContext';
// 🔗 API calls
import { authRole, registerUser } from '../utils/apiCallAuth';
import { getTable } from '../utils/apiCallCrud';
// 📢 Sweet Alert 2 like toast massage
import { ToastTop } from '../components/SwalModalToast/SwalModalToast';
// 🔨 Utils Fields
import { ROLE } from '../utils/const';
import { SearchItem, ClearButton, sortCaret, imageFormatter, handleToggleSidebarRight } from '../utils/utils';
// 📦 Components
import Loader from '../components/Loading/Loader';
import SidebarRight from '../components/Sidebar/SidebarRight';
// 🍪 Cookies
import Cookies from 'js-cookie';

/**
 * UsersTable View
 *
 * @augments {Component<Props, State>}
 */

const UsersTable = ({ history }) => {
  // * Translations
  const { t } = useTranslation();

  // * Authentication user
  // replaced user with token
  // const { user } = useContext(UserContext);

  useEffect(() => {
    let token = Cookies.get('SID');
    if (!token) {
      history.push('/login');
    }
  }, [history]);

  // * Validating form state Bootstrap
  const [validated, setValidated] = useState(false);

  // * UserName / Email
  const [credencials, setCredencials] = useState({
    username: '',
    email: '',
  });

  // * Loading
  const [loading, setLoading] = useState(false);

  // * Main Data
  const [tableData, setTableData] = useState([]);

  // * MAIN DATA CALL
  useEffect(() => {
    let isClean = true;
    setLoading(true);

    let userId = Cookies.get('ID');

    authRole(userId, ROLE.ADMIN).then((status) => {
      if (status === 'true') {
        //privileged user, can see data
        setTimeout(() => {
          getTable('users').then((data) => {
            if (isClean) {
              setTableData(data);
              setLoading(false);
              return;
            }
          });
        }, 500);
      } else {
        //user does not have permissions, handle here
        setLoading(false);
      }
    });

    getTable('item_status').then((data) => {
      //console.log("data je: ", data);
    });
    return () => (isClean = false);
  }, []);

  // * TABLE COLUMN and OPTIONS SETTINS
  const defaultSorted = [
    {
      dataField: 'usr_id',
      order: 'asc',
    },
  ];

  const options = {
    // custom: true,
    // totalSize: totalSize,
    sizePerPageList: [
      {
        text: '10',
        value: 10,
      },
      {
        text: '15',
        value: 15,
      },
    ],
    paginationSize: 3, // The pagination bar size, default is 5
    alwaysShowAllBtns: true, // The pagination bar button show all
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: totalSize === 0, // Hide the sizePerPage dropdown
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    showTotal: true,

    nextPageTitle: 'Next page',
    prePageTitle: 'Previous page',
    firstPageTitle: 'First page',
    lastPageTitle: 'Last page',

    disablePageTitle: true,
  };

  const columns = [
    {
      dataField: 'usr_id',
      text: 'ID',
      sort: true,
      sortCaret: sortCaret,
      headerStyle: () => {
        return { minWidth: '48px' };
      },
    },
    {
      dataField: 'picture',
      text: '',
      formatter: imageFormatter,
      headerStyle: () => {
        return { maxWidth: '40px', width: '40px' };
      },
    },
    {
      dataField: 'usr_fullname',
      text: 'Name',
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: 'usr_username',
      text: 'Email',
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: 'usr_desc',
      text: 'Description',
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  // * HANDLE RESULTS
  const handleResult = async (results) => {
    let { status, error } = results;
    if (error) {
      ToastTop.fire({
        icon: 'error',
        title: t(error),
      });
      return;
    }
    if (status === 200) {
      ToastTop.fire({
        icon: 'success',
        title: t('swal.createedit.success.title'),
      });

      setTimeout(() => {
        setValidated(false);
      }, 2500);
    }
  };

  // * HANDLE SUBMIT for Register User
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      // Postavljena ovdje kontrola da ne prodje ako se ne poklapaju pass - nema u defaultnoj checkValidity
      if (credencials.password !== credencials.confirmpassword) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        event.preventDefault();
        registerUser(credencials.email, credencials.password, credencials.username).then((data) => {
          handleResult(data);
        });
      }
    }
  };

  // * HANDLE CHANGE input
  const handleOnChange = (e) => {
    setCredencials({
      ...credencials,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <>
      <SidebarRight title={'Add New User'}>
        <Card className="w-100 text-left">
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit} autoComplete="off">
              <Form.Row>
                <Form.Group as={Col} md={12} className="px-md-1" controlId="usr_fullname">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="usr_fullname"
                    value={credencials.usr_fullname}
                    placeholder="Enter Full Name"
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={12} className="px-md-1" controlId="usr_username">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    name="usr_username"
                    placeholder="Email address"
                    value={credencials.usr_username}
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md={12} className="text-center px-md-1" controlId="usr_username">
                  <Button variant="primary" type="submit" className="btn-fill">
                    {t('table.btn.save')}
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
        </Card>
      </SidebarRight>

      <Container fluid className="content">
        <Row>
          <ToolkitProvider bootstrap4 keyField="usr_id" data={tableData} columns={columns} search>
            {(props) => (
              <Col xs="12">
                <Row className="mb-3 mb-sm-1">
                  <Col xs="12" sm="8">
                    <Row>
                      <Col xs="10" lg="11" className="pr-0">
                        <SearchItem {...props.searchProps} />
                      </Col>
                      <Col xs="2" lg="1" className="px-0">
                        <ClearButton {...props.searchProps} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" sm="4" className="pl-lg-0">
                    <Button variant="link" className="m-0" onClick={handleToggleSidebarRight}>
                      <FontAwesomeIcon icon={['far', 'plus-hexagon']} size={'lg'} /> Add User
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    {loading === false ? (
                      <BootstrapTable
                        {...props.baseProps}
                        keyField="usr_id"
                        data={tableData}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        wrapperClasses="table-responsive"
                        hover={true}
                        bordered={false}
                        pagination={paginationFactory(options)}
                        noDataIndication="Table is Empty"
                      />
                    ) : (
                      <Loader />
                    )}
                  </Col>
                </Row>
              </Col>
            )}
          </ToolkitProvider>
        </Row>
      </Container>
    </>
  );
};

export default UsersTable;
