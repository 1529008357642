// 🌌 React
import React, { createContext, useState, useEffect } from 'react';
// 🍪 Cookies
import Cookies from 'js-cookie';

/**
 * UserContext
 *
 * @augments {Component<Props, State>}
 */

export const UserContext = createContext(null);

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    let token = Cookies.get('SID');
    if (token) {
      setUser(token);
    } else {
      setUser(null);
    }
  }, []);
  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};
