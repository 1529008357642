/**
 * ERROR HANDLER
 *
 * @augments utils
 */

// Handle Error Default Response
const DEFAULET_RESPONSE = {
  sid: null,
  user: null,
  data: null,
  error: 'Network Error',
};

export const handleError = (error) => {
  // All Error from err:
  if (error) {
    var data = error.data.err;
    if (data) {
      let err = {
        ...DEFAULET_RESPONSE,
        error: data,
      };
      return err;
    }
    // 401 "Unauthorized"
    else if (!data) {
      let err = {
        ...DEFAULET_RESPONSE,
        error: error.statusText,
      };
      return err;
    }
  }

  return DEFAULET_RESPONSE;
};
