// 🌌 React
import React from 'react';

// 🔗 API calls
import { testWebSocket, getMeetingId, getMeetingPassword } from '../../utils/apiCallCrud';

import Cookies from 'js-cookie';

// Game css
import '../Game/Game.css';

import { joinMeet, startMeeting } from '../Zoom/Zoom';

let x, y;
let userId, meetingId, meetingPassword;

var users = [];

var existingIds = [];

var pixelSize = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--pixel-size'));

export function movePlayer(data) {
  let newData = JSON.parse(data);
  let newId = newData.usr_id;
  let newX = newData.x;
  let newY = newData.y;
  let direction = newData.direction;
  if (users.some((user) => user.usr_id === newId)) {
    // vec postoji korisnik u arrayu
  } else {
    // nemamo korisnika u arrayu, dodaj ga
    users.push(newData);
  }

  spawnPlayers();

  if (parseInt(newId) !== parseInt(userId)) {
    // move player with id == newId

    var selectedChar = document.getElementById(newId);
    if (selectedChar) {
      selectedChar.style.transform = `translate3d( ${newX * pixelSize}px, ${newY * pixelSize}px, 0 )`;
      selectedChar.setAttribute('facing', direction);
    }

    var upperX = x + 15;
    var lowerX = x - 15;
    var upperY = y + 15;
    var lowerY = y - 15;

    if (upperX >= newX && newX >= lowerX && upperY >= newY && newY >= lowerY) {
      // korisnici su blizu
      document.getElementById('startCall').removeAttribute('disabled');

      var existingBubble = document.querySelector('.meetingBubble');
      if (!existingBubble) {
        var meetingBubble = document.createElement('div');
      } else {
        var meetingBubble = existingBubble;
      }

      var centerX = (x + newX) / 2;
      var centerY = (y + newY) / 2;

      meetingBubble.classList.add('meetingBubble');

      meetingBubble.style.transform = `translate3d( ${centerX * pixelSize}px, ${centerY * pixelSize}px, 0 )`;
      meetingBubble.style.display = 'block';
      var map = document.querySelector('.map');
      map.appendChild(meetingBubble);
    } else {
      var existingBubble = document.querySelector('.meetingBubble');
      if (existingBubble) {
        existingBubble.style.display = 'none';
      }
      document.getElementById('startCall').setAttribute('disabled', true);
    }
  }
}

export function spawnPlayers() {
  // provjeri koji su vec generisani korisnici

  var existingCharacters = document.getElementsByClassName('character');
  var i;
  for (i = 0; i < existingCharacters.length; i++) {
    var existingId = existingCharacters[i].id;
    if (!existingIds.includes(parseInt(existingId))) {
      existingIds.push(parseInt(existingId));
    }
  }

  if (users.length > 0) {
    users.forEach((user) => {
      let charId = parseInt(user.usr_id);
      var charX = user.x;
      var charY = user.y;

      // ako je charId definisian, nije u existingIds arrayu i nije trenutno logovani korisnik, spawnaj gost igraca
      if (charId && !existingIds.includes(charId) && parseInt(userId) !== charId) {
        var characterShadow = document.createElement('div');
        characterShadow.classList.add('shadow', 'pixel-art');

        var characterSprite = document.createElement('div');
        characterSprite.classList.add('character_spritesheet', 'pixel-art');

        var character = document.createElement('div');
        character.id = charId;
        character.className = 'character guest';
        character.setAttribute('facing', 'down');
        character.setAttribute('walking', 'true');

        var colors = ['#6cc2bd', '#5a819e', '#f67e7d', '#ffe5c4', '#ffc1a7', '#7d7aa2', 'black'];
        var divisionNumbers = [7, 6, 5, 4, 3, 2, 1];
        var starting = 0;
        while (6 >= starting) {
          var result = charId % divisionNumbers[starting];
          if (result === 0) {
            characterSprite.style.backgroundColor = colors[starting];
            break;
          }
          starting++;
        }

        character.appendChild(characterShadow);
        character.appendChild(characterSprite);

        var map = document.querySelector('.map');
        map.appendChild(character);

        //Limits (gives the illusion of walls)
        var leftLimit = -8;
        var rightLimit = 16 * 20 + 8;
        var topLimit = -24 + 32;
        var bottomLimit = 16 * 9;
        if (charX < leftLimit) {
          charX = leftLimit;
        }
        if (charX > rightLimit) {
          charX = rightLimit;
        }
        if (charY < topLimit) {
          charY = topLimit;
        }
        if (charY > bottomLimit) {
          charY = bottomLimit;
        }

        character.style.transform = `translate3d( ${charX * pixelSize}px, ${charY * pixelSize}px, 0 )`;
      }
    });
  }
}

function joinMeeting(e) {
  e.preventDefault();
  document.querySelector('.frame').style.display = 'none';
  document.getElementById('startCall').style.display = 'none';
  document.getElementById('hostCall').style.display = 'none';
  document.querySelector('.sidebar').style.display = 'none';
  document.querySelector('.navbar').style.display = 'none';
  document.querySelector('.footer').style.display = 'none';
  document.querySelector('.fixed-plugin').style.display = 'none';
  joinMeet();
}

function hostMeeting(e) {
  e.preventDefault();
  document.querySelector('.frame').style.display = 'none';
  document.getElementById('startCall').style.display = 'none';
  document.getElementById('hostCall').style.display = 'none';
  document.querySelector('.sidebar').style.display = 'none';
  document.querySelector('.navbar').style.display = 'none';
  document.querySelector('.footer').style.display = 'none';
  document.querySelector('.fixed-plugin').style.display = 'none';
  startMeeting();
}

class Game extends React.Component {
  componentDidMount() {
    userId = Cookies.get('ID');

    const gameData = async () => {
      meetingId = await getMeetingId();
      meetingPassword = await getMeetingPassword();
      Cookies.set('meetingId', meetingId);
      Cookies.set('meetingPassword', meetingPassword);
    };

    gameData();

    document.getElementById('startCall').setAttribute('disabled', true);

    var characterShadow = document.createElement('div');
    characterShadow.classList.add('shadow', 'pixel-art');

    var characterSprite = document.createElement('div');
    characterSprite.classList.add('character_spritesheet', 'pixel-art');

    var character = document.createElement('div');
    character.id = userId;
    character.className = 'character';
    character.setAttribute('facing', 'down');
    character.setAttribute('walking', 'true');

    var colors = ['#6cc2bd', '#5a819e', '#f67e7d', '#ffe5c4', '#ffc1a7', '#7d7aa2', '#3fcc91'];
    var divisionNumbers = [7, 6, 5, 4, 3, 2, 1];
    var starting = 0;
    while (6 >= starting) {
      var result = userId % divisionNumbers[starting];
      console.log('userID je:', userId, ', rezultat je: ', result, ',starting je: ', starting);
      if (result === 0) {
        characterSprite.style.backgroundColor = colors[starting];
        break;
      }
      starting++;
    }

    /* var randomNumber = Math.floor(Math.random() * 5);

    switch (randomNumber) {
      case 0:
        characterSprite.style.backgroundColor = '#6cc2bd';
        break;
      case 1:
        characterSprite.style.backgroundColor = '#5a819e';
        break;
      case 2:
        characterSprite.style.backgroundColor = '#f67e7d';
        break;
      case 3:
        characterSprite.style.backgroundColor = '#ffe5c4';
        break;
      case 4:
        characterSprite.style.backgroundColor = '#ffc1a7';
        break;
      case 5:
        characterSprite.style.backgroundColor = '#7d7aa2';
        break;
      default:
        characterSprite.style.backgroundColor = 'black';
        break;
    } */

    character.appendChild(characterShadow);
    character.appendChild(characterSprite);

    var map = document.querySelector('.map');
    map.appendChild(character);

    // start at a random location

    x = Math.floor(Math.random() * 80);
    y = Math.floor(Math.random() * 60);
    var held_directions = []; //State of which arrow keys we are holding down
    var speed = 0.3; //How fast the character moves in pixels per frame

    const placeCharacter = () => {
      const held_direction = held_directions[0];
      if (held_direction) {
        if (held_direction === directions.right) {
          x += speed;
        }
        if (held_direction === directions.left) {
          x -= speed;
        }
        if (held_direction === directions.down) {
          y += speed;
        }
        if (held_direction === directions.up) {
          y -= speed;
        }
        character.setAttribute('facing', held_direction);
      }
      character.setAttribute('walking', held_direction ? 'true' : 'false');

      //Limits (gives the illusion of walls)
      var leftLimit = -8;
      var rightLimit = 16 * 20 + 8;
      var topLimit = -24 + 32;
      var bottomLimit = 16 * 9;
      if (x < leftLimit) {
        x = leftLimit;
      }
      if (x > rightLimit) {
        x = rightLimit;
      }
      if (y < topLimit) {
        y = topLimit;
      }
      if (y > bottomLimit) {
        y = bottomLimit;
      }

      var camera_left = pixelSize * 66;
      var camera_top = pixelSize * 42;

      map.style.transform = `translate3d( ${-x * pixelSize + camera_left}px, ${-y * pixelSize + camera_top}px, 0 )`;
      character.style.transform = `translate3d( ${x * pixelSize}px, ${y * pixelSize}px, 0 )`;
    };

    const sendPosition = (userId) => {
      var newX = parseInt(x);
      var newY = parseInt(y);
      var direction = held_directions[0];
      if (!direction) {
        direction = 'static';
      }

      // Send data to API
      var position = { x: newX, y: newY };
      testWebSocket(userId, position, direction);
    };

    let i = 0;

    //Set up the game loop
    const step = () => {
      if (i % 30 === 0 && userId) {
        sendPosition(userId);
      }
      placeCharacter();
      window.requestAnimationFrame(() => {
        step();
      });
      i = i + 1;
    };
    step(); //kick off the first step!

    /* Direction key state */
    const directions = {
      up: 'up',
      down: 'down',
      left: 'left',
      right: 'right',
    };
    const keys = {
      38: directions.up,
      37: directions.left,
      39: directions.right,
      40: directions.down,
    };
    document.addEventListener('keydown', (e) => {
      var dir = keys[e.which];
      if (dir && held_directions.indexOf(dir) === -1) {
        held_directions.unshift(dir);
      }
    });

    document.addEventListener('keyup', (e) => {
      var dir = keys[e.which];
      var index = held_directions.indexOf(dir);
      if (index > -1) {
        held_directions.splice(index, 1);
      }
    });

    /* BONUS! Dpad functionality for mouse and touch */
    var isPressed = false;
    const removePressedAll = () => {
      document.querySelectorAll('.dpad-button').forEach((d) => {
        d.classList.remove('pressed');
      });
    };
    document.body.addEventListener('mousedown', () => {
      isPressed = true;
    });
    document.body.addEventListener('mouseup', () => {
      isPressed = false;
      held_directions = [];
      removePressedAll();
    });
    const handleDpadPress = (direction, click) => {
      if (click) {
        isPressed = true;
      }
      held_directions = isPressed ? [direction] : [];

      if (isPressed) {
        removePressedAll();
        document.querySelector('.dpad-' + direction).classList.add('pressed');
      }
    };
    //Bind a ton of events for the dpad
    document.querySelector('.dpad-left').addEventListener('touchstart', (e) => handleDpadPress(directions.left, true));
    document.querySelector('.dpad-up').addEventListener('touchstart', (e) => handleDpadPress(directions.up, true));
    document.querySelector('.dpad-right').addEventListener('touchstart', (e) => handleDpadPress(directions.right, true));
    document.querySelector('.dpad-down').addEventListener('touchstart', (e) => handleDpadPress(directions.down, true));

    document.querySelector('.dpad-left').addEventListener('mousedown', (e) => handleDpadPress(directions.left, true));
    document.querySelector('.dpad-up').addEventListener('mousedown', (e) => handleDpadPress(directions.up, true));
    document.querySelector('.dpad-right').addEventListener('mousedown', (e) => handleDpadPress(directions.right, true));
    document.querySelector('.dpad-down').addEventListener('mousedown', (e) => handleDpadPress(directions.down, true));

    document.querySelector('.dpad-left').addEventListener('mouseover', (e) => handleDpadPress(directions.left));
    document.querySelector('.dpad-up').addEventListener('mouseover', (e) => handleDpadPress(directions.up));
    document.querySelector('.dpad-right').addEventListener('mouseover', (e) => handleDpadPress(directions.right));
    document.querySelector('.dpad-down').addEventListener('mouseover', (e) => handleDpadPress(directions.down));

    // Make the DIV element draggable:
    dragElement(document.getElementById('mydiv'));

    function dragElement(elmnt) {
      var pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      if (document.getElementById(elmnt.id + 'header')) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
        elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }

    var video = document.querySelector('#videoElement');

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          video.srcObject = stream;
        })
        .catch(function (err0r) {
          console.log('Something went wrong!');
        });
    }
  }

  componentWillUnmount() {
    //cleanup
  }

  render() {
    return (
      <div className="all">
        <div className="frame">
          <div className="corner_topleft"></div>
          <div className="corner_topright"></div>
          <div className="corner_bottomleft"></div>
          <div className="corner_bottomright"></div>

          <div className="camera">
            <div className="map pixel-art"></div>

            <div className="dpad">
              <div className="DemoDirectionUI flex-center">
                <button className="dpad-button dpad-left">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 13 13" shapeRendering="crispEdges">
                    <path
                      className="Arrow_outline-top"
                      stroke="#5f5f5f"
                      d="M1 0h11M0 1h1M12 1h1M0 2h1M12 2h1M0 3h1M12 3h1M0 4h1M12 4h1M0 5h1M12 5h1M0 6h1M12 6h1M0 7h1M12 7h1M0 8h1M12 8h1"
                    />
                    <path className="Arrow_surface" stroke="#f5f5f5" d="M1 1h11M1 2h11M1 3h5M7 3h5M1 4h4M7 4h5M1 5h3M7 5h5M1 6h4M7 6h5M1 7h5M7 7h5M1 8h11" />
                    <path className="Arrow_arrow-inset" stroke="#434343" d="M6 3h1M5 4h1M4 5h1" />
                    <path className="Arrow_arrow-body" stroke="#5f5f5f" d="M6 4h1M5 5h2M5 6h2M6 7h1" />
                    <path className="Arrow_outline-bottom" stroke="#434343" d="M0 9h1M12 9h1M0 10h1M12 10h1M0 11h1M12 11h1M1 12h11" />
                    <path className="Arrow_edge" stroke="#ffffff" d="M1 9h11" />
                    <path className="Arrow_front" stroke="#cccccc" d="M1 10h11M1 11h11" />
                  </svg>
                </button>
                <button className="dpad-button dpad-up">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 13 13" shapeRendering="crispEdges">
                    <path
                      className="Arrow_outline-top"
                      stroke="#5f5f5f"
                      d="M1 0h11M0 1h1M12 1h1M0 2h1M12 2h1M0 3h1M12 3h1M0 4h1M12 4h1M0 5h1M12 5h1M0 6h1M12 6h1M0 7h1M12 7h1M0 8h1M12 8h1"
                    />
                    <path className="Arrow_surface" stroke="#f5f5f5" d="M1 1h11M1 2h11M1 3h11M1 4h5M7 4h5M1 5h4M8 5h4M1 6h3M9 6h3M1 7h11M1 8h11" />
                    <path className="Arrow_arrow-inset" stroke="#434343" d="M6 4h1M5 5h1M7 5h1" />
                    <path className="Arrow_arrow-body" stroke="#5f5f5f" d="M6 5h1M4 6h5" />
                    <path className="Arrow_outline-bottom" stroke="#434343" d="M0 9h1M12 9h1M0 10h1M12 10h1M0 11h1M12 11h1M1 12h11" />
                    <path className="Arrow_edge" stroke="#ffffff" d="M1 9h11" />
                    <path className="Arrow_front" stroke="#cccccc" d="M1 10h11M1 11h11" />
                  </svg>
                </button>
                <button className="dpad-button dpad-down">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 13 13" shapeRendering="crispEdges">
                    <path
                      className="Arrow_outline-top"
                      stroke="#5f5f5f"
                      d="M1 0h11M0 1h1M12 1h1M0 2h1M12 2h1M0 3h1M12 3h1M0 4h1M12 4h1M0 5h1M12 5h1M0 6h1M12 6h1M0 7h1M12 7h1M0 8h1M12 8h1"
                    />
                    <path className="Arrow_surface" stroke="#f5f5f5" d="M1 1h11M1 2h11M1 3h11M1 4h3M9 4h3M1 5h4M8 5h4M1 6h5M7 6h5M1 7h11M1 8h11" />
                    <path className="Arrow_arrow-inset" stroke="#434343" d="M4 4h5" />
                    <path className="Arrow_arrow-body" stroke="#5f5f5f" d="M5 5h3M6 6h1" />
                    <path className="Arrow_outline-bottom" stroke="#434343" d="M0 9h1M12 9h1M0 10h1M12 10h1M0 11h1M12 11h1M1 12h11" />
                    <path className="Arrow_edge" stroke="#ffffff" d="M1 9h11" />
                    <path className="Arrow_front" stroke="#cccccc" d="M1 10h11M1 11h11" />
                  </svg>
                </button>
                <button className="dpad-button dpad-right">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -0.5 13 13" shapeRendering="crispEdges">
                    <path
                      className="Arrow_outline-top"
                      stroke="#5f5f5f"
                      d="M1 0h11M0 1h1M12 1h1M0 2h1M12 2h1M0 3h1M12 3h1M0 4h1M12 4h1M0 5h1M12 5h1M0 6h1M12 6h1M0 7h1M12 7h1M0 8h1M12 8h1"
                    />
                    <path className="Arrow_surface" stroke="#f5f5f5" d="M1 1h11M1 2h11M1 3h5M7 3h5M1 4h5M8 4h4M1 5h5M9 5h3M1 6h5M8 6h4M1 7h5M7 7h5M1 8h11" />
                    <path className="Arrow_arrow-inset" stroke="#434343" d="M6 3h1M7 4h1M8 5h1" />
                    <path className="Arrow_arrow-body" stroke="#5f5f5f" d="M6 4h1M6 5h2M6 6h2M6 7h1" />
                    <path className="Arrow_outline-bottom" stroke="#434343" d="M0 9h1M12 9h1M0 10h1M12 10h1M0 11h1M12 11h1M1 12h11" />
                    <path className="Arrow_edge" stroke="#ffffff" d="M1 9h11" />
                    <path className="Arrow_front" stroke="#cccccc" d="M1 10h11M1 11h11" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*  <!-- Draggable DIV --> */}
        <div id="mydiv">
          {/* <!-- Include a header DIV with the same name as the draggable DIV, followed by "header" --> */}
          <div id="mydivheader">Video window</div>
          <video autoPlay={true} id="videoElement"></video>
        </div>

        <div className="zoomSwitch">
          <button type="button" id="startCall" className="btn btn-primary" onClick={joinMeeting}>
            Join call
          </button>
          <button type="button" id="hostCall" className="btn btn-danger" onClick={hostMeeting}>
            Host call
          </button>
        </div>
      </div>
    );
  }
}

export default Game;
