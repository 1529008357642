// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Loader Animation
 *
 * @augments {Component<Props, State>}
 */

const Loader = () => (
  <div className="loader text-center my-3">
    <FontAwesomeIcon icon={['fad', 'spinner-third']} size={'4x'} spin className="fa-spin" />
  </div>
);
export default Loader;
