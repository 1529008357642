// 🌌 React
import React, { useState, useEffect } from 'react';
// 🚧 React router
import { NavLink, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
// ⛽️ Bootstrap
import { Container, Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
// 🔐 Authentication
//import { UserContext } from '../../context/UserContext';
// 🌍 Translations
import { useTranslation } from 'react-i18next';
// 🔗 API calls
import { getUserFullname } from '../../utils/apiCallCrud';
// 🔨 Utils Fields
import { API_URL, TABLE, USERIMG } from '../../utils/const';
// 🍪 Cookies
import Cookies from 'js-cookie';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// ➰ Concatenates classes
import classNames from 'classnames';

/**
 * NavbarDoc Component
 *
 * @augments {Component<Props, State>}
 */

const NavbarDoc = (props) => {
  // * Translation
  const { t } = useTranslation();
  // * Authentication user state
  //  const { user, setUser } = useContext(UserContext);

  let token = Cookies.get('SID');
  let userId = Cookies.get('ID');
  // * Set state for expanded, color, modal search and getWidth
  const [expanded, setExpanded] = useState(false);
  const [color, setColor] = useState('navbar-transparent');
  // const [modalSearch, setModalSearch] = useState(false);
  const getWidth = () => window.innerWidth;

  // * Name for title below image
  const [userName, setUsername] = useState('');

  let userImage = API_URL + TABLE + USERIMG + userId;

  // * Close navbar main button
  const openCloseNavbarMain = () => {
    if (expanded) {
      setColor('navbar-transparent');
    } else {
      setColor('bg-white');
    }
    setExpanded(expanded ? false : true);
  };

  // * Close navbar on selected items
  const closeNavbar = () => {
    if (expanded) {
      setExpanded(false);
      setColor('navbar-transparent');
    }
  };

  // * Change opened class if expanded for SVG to animate i hamburger menu main
  const classOpened = expanded ? 'opened' : '';

  // * Toggle Modal Search
  // const toggleModalSearch = () => setModalSearch(!modalSearch);

  // * Change color white/transparent to the navbar on resize
  useEffect(() => {
    const data = async () => {
      if (userId) {
        const response = await getUserFullname(userId);
        let firstName = response[0].usr_fullname;
        setUsername(firstName);
      }
    };
    data();
  }, [userId]);

  useEffect(() => {
    const updateColor = () => {
      if (getWidth < 993 && expanded === true) {
        setColor('bg-white');
      } else {
        setColor('navbar-transparent');
      }
    };
    window.addEventListener('resize', updateColor); // set resize listener
    return () => {
      // cleanup
      window.removeEventListener('resize', updateColor); // remove resize listener
    };
  }, [expanded]);

  // * LogOut
  const logOut = () => {
    Cookies.remove('SID');
    Cookies.remove('ID');
    setTimeout(() => {
      //setUser(null);
      props.history.push('/login');
    }, 650);
  };

  return (
    <Navbar collapseOnSelect className={classNames('navbar-absolute', color)} expand="lg" expanded={expanded}>
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-minimize d-inline">
            <Button variant="link" className="minimize-sidebar" id="sidebartogglett" onClick={props.handleMiniClick}>
              <span className="visible-on-sidebar-regular">
                <FontAwesomeIcon icon={['fad', 'list-alt']} size="2x" fixedWidth />
              </span>
              <span className="visible-on-sidebar-mini">
                <FontAwesomeIcon icon={['fal', 'ellipsis-v-alt']} size="2x" fixedWidth />
              </span>
            </Button>
          </div>
          <div
            className={classNames('navbar-toggle d-inline', {
              toggled: props.sidebarOpened,
            })}
          >
            <button className="navbar-toggler p-0" type="button" onClick={props.toggleSidebar}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Navbar.Brand href="#erodin" onClick={(e) => e.preventDefault()}>
            {props.brandText}
          </Navbar.Brand>
        </div>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" className={`menu ${classOpened} p-0`} onClick={openCloseNavbarMain}>
          <svg width="40" height="40" viewBox="0 0 100 100">
            <path
              className="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path className="line line2" d="M 20,50 H 80" />
            <path
              className="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          {/* <Nav className="ml-auto">
            <InputGroup className="search-bar">
              <Button
                variant="link"
                className="mx-0 mx-lg-2 px-0 text-left"
                data-target="#searchModal"
                data-toggle="modal"
                id="search-button"
                onClick={toggleModalSearch}
              >
                <FontAwesomeIcon icon={['fal', 'search']} size="lg" fixedWidth className="d-inline-block" />
                <span className="ml-2 d-inline-block d-lg-none">Search</span>
              </Button>
            </InputGroup>
          </Nav> */}

          <Nav className="ml-auto">
            <NavDropdown
              alignRight
              title={
                <>
                  <div className="notification d-none d-lg-block d-xl-block" />
                  <FontAwesomeIcon icon={['fal', 'bell-on']} size="lg" fixedWidth />
                  <p className="d-lg-none">Notifications</p>
                </>
              }
              id="notification-dropdown"
              className="py-0"
            >
              <LinkContainer to="/notification">
                <NavDropdown.Item onClick={() => closeNavbar()}>Erodin responded to your email</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/notification">
                <NavDropdown.Item onClick={() => closeNavbar()}>You have 5 more tasks</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/notification">
                <NavDropdown.Item onClick={() => closeNavbar()}>Amela responded to your email</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/notification">
                <NavDropdown.Item onClick={() => closeNavbar()}>Another notification</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/notification">
                <NavDropdown.Item onClick={() => closeNavbar()}>Another one</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>

          <Nav>
            <NavDropdown
              alignRight
              title={
                <>
                  <div className="d-none d-lg-flex">
                    <div className="photo d-none d-lg-block">
                      <img alt="..." src={userImage} />
                    </div>
                    <FontAwesomeIcon icon={['fas', 'caret-down']} fixedWidth className="mt-2 d-none d-lg-flex" />
                  </div>
                  <FontAwesomeIcon icon={['fad', 'user']} size="lg" fixedWidth className="d-lg-none" />
                  <p className="d-lg-none">{userName}</p>
                </>
              }
              id="profile-dropdown"
              className="py-0"
            >
              <LinkContainer to="/admin/user-profile">
                <NavDropdown.Item onClick={() => closeNavbar()}>Profile</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/admin/dashboard">
                <NavDropdown.Item onClick={() => closeNavbar()}>Settings</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>

          {token && (
            <Nav className="mt-2 mb-3 my-lg-0">
              <Button
                variant="primary"
                block
                className="mx-0 px-3"
                onClick={() => {
                  closeNavbar();
                  logOut();
                }}
              >
                <FontAwesomeIcon icon={['fad', 'sign-out-alt']} fixedWidth /> {t('nav.logout')}
              </Button>
            </Nav>
          )}
          {!token && (
            <Nav>
              <NavLink to="/register">
                <Button variant="link" block className="mx-0" onClick={() => closeNavbar()}>
                  <FontAwesomeIcon icon={['fad', 'user-plus']} fixedWidth /> {t('nav.register')}
                </Button>
              </NavLink>
            </Nav>
          )}
          {!token && (
            <Nav>
              <NavLink to="/login">
                <Button variant="link" block className="mx-0" onClick={() => closeNavbar()}>
                  <FontAwesomeIcon icon={['fad', 'sign-in-alt']} fixedWidth /> {t('nav.login')}
                </Button>
              </NavLink>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default withRouter(NavbarDoc);
