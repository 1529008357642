// 🌌 React
import React from 'react';

// Zoom sdk
import { ZoomMtg } from '@zoomus/websdk';

// Zoom css
import '../Game/bootstrap.css';
import '../Game/react-select.css';

import Cookies from 'js-cookie';

let meetingId = Cookies.get('meetingId');
let meetingPassword = Cookies.get('meetingPassword');

let apiKeys = {
  apiKey: 'WpTMsp8hRtKiLvfymLmSHg',
  apiSecret: 'jCfbY3Yblju49tI7qCfSZQM8OKAEeFqNr83p',
};
let meetConfig = {
  apiKey: apiKeys.apiKey,
  meetingNumber: meetingId,
  userName: 'Korisnik',
  userEmail: 'test@example.com', // must be the attendee email address
  passWord: meetingPassword,
  role: 0,
};
let hostMeetConfig = {
  apiKey: apiKeys.apiKey,
  meetingNumber: meetingId,
  userName: 'Firstname Lastname',
  passWord: meetingPassword,
  role: 1, // 1 for host
};

let isMeetingOn = false;

function hostMeeting(signature, hostMeetConfig) {
  ZoomMtg.init({
    leaveUrl: 'https://app.doc.ba/admin/zoomgame',
    isSupportAV: true,
    success: function (success) {
      console.log('Init Success ', success);
      ZoomMtg.join({
        meetingNumber: hostMeetConfig.meetingNumber,
        userName: hostMeetConfig.userName,
        signature: signature,
        apiKey: hostMeetConfig.apiKey,
        passWord: hostMeetConfig.passWord,

        success: (success) => {
          console.log(success);
        },

        error: (error) => {
          console.log(error);
        },
      });
    },
  });
}

function joinMeeting(signature, meetConfig) {
  ZoomMtg.init({
    leaveUrl: 'https://app.doc.ba/admin/zoomgame',
    isSupportAV: true,
    success: function (success) {
      console.log('Init Success ', success);
      ZoomMtg.join({
        meetingNumber: meetConfig.meetingNumber,
        userName: meetConfig.userName,
        signature: signature,
        apiKey: meetConfig.apiKey,
        passWord: meetConfig.passWord,

        success: (success) => {
          console.log(success);
        },

        error: (error) => {
          console.log(error);
        },
      });
    },
  });
}

const startMeet = () => {
  if (!isMeetingOn) {
    console.log('pocinjem inicijalizaciju, vrijednost isMeetingOn: ', isMeetingOn);
    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.8.6/lib', '/av');
    //ZoomMtg.setZoomJSLib('../node_modules/@zoomus/websdk/dist/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    /**
     * You should not visible api secret key on frontend
     * Signature must be generated on server
     * https://marketplace.zoom.us/docs/sdk/native-sdks/web/essential/signature
     */
    ZoomMtg.generateSignature({
      meetingNumber: meetConfig.meetingNumber,
      apiKey: meetConfig.apiKey,
      apiSecret: apiKeys.apiSecret,
      role: meetConfig.role,
      success: function (res) {
        setTimeout(() => {
          console.log('joining meeting with signature: ', res.result, ' and meetConfig: ', meetConfig);
          joinMeeting(res.result, meetConfig);
          setTimeout(() => {
            if (document.getElementById('sharingViewOptions')) {
              document.getElementById('sharingViewOptions').onclick = function () {
                var viewOptions = document.getElementsByClassName('sharee-sharing-indicator__menu dropdown-menu')[0];

                var ariaExpanded = document.getElementById('sharingViewOptions').getAttribute('aria-expanded');

                if (ariaExpanded === 'false') {
                  viewOptions.style.visibility = 'visible';
                  viewOptions.style.opacity = '1';
                  viewOptions.style.marginTop = '20px';
                } else {
                  viewOptions.style.visibility = 'hidden';
                  viewOptions.style.opacity = '0';
                }
              };
            }
            if (document.getElementById('moreButton')) {
              document.getElementById('moreButton').onclick = function () {
                var screenOptions = document.getElementsByClassName('more-button__pop-menu dropdown-menu')[0];

                var ariaExpanded = document.getElementById('moreButton').getAttribute('aria-expanded');

                if (ariaExpanded === 'false') {
                  screenOptions.style.visibility = 'visible';
                  screenOptions.style.opacity = '1';
                  screenOptions.style.marginBottom = '20px !important';
                } else {
                  screenOptions.style.visibility = 'hidden';
                  screenOptions.style.opacity = '0';
                }
              };
            }
            if (document.getElementById('audioOptionMenu')) {
              document.getElementById('audioOptionMenu').onclick = function () {
                var audioOptions = document.getElementsByClassName('audio-option-menu__pop-menu dropdown-menu')[0];

                var ariaExpanded = document.getElementById('audioOptionMenu').getAttribute('aria-expanded');

                if (ariaExpanded === 'false') {
                  audioOptions.style.visibility = 'visible';
                  audioOptions.style.opacity = '1';
                } else {
                  audioOptions.style.visibility = 'hidden';
                  audioOptions.style.opacity = '0';
                }
              };
            }
          }, 10000);
        }, 3000);
      },
    });
    isMeetingOn = true;
  }
};

const hostMeet = () => {
  if (!isMeetingOn) {
    console.log('pocinjem inicijalizaciju, vrijednost isMeetingOn: ', isMeetingOn);
    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.8.6/lib', '/av');
    //ZoomMtg.setZoomJSLib('../node_modules/@zoomus/websdk/dist/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    /**
     * You should not visible api secret key on frontend
     * Signature must be generated on server
     * https://marketplace.zoom.us/docs/sdk/native-sdks/web/essential/signature
     */
    ZoomMtg.generateSignature({
      meetingNumber: hostMeetConfig.meetingNumber,
      apiKey: hostMeetConfig.apiKey,
      apiSecret: apiKeys.apiSecret,
      role: hostMeetConfig.role,
      success: function (res) {
        setTimeout(() => {
          hostMeeting(res.result, hostMeetConfig);
        }, 3000);
      },
    });
    isMeetingOn = true;
  }
};

const Zoom = () => {
  return <div className="App"></div>;
};

export function joinMeet() {
  startMeet();
}

export function startMeeting() {
  hostMeet();
}

export default Zoom;
