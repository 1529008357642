// 📢 Sweet Alert 2
import Swal from 'sweetalert2';

/**
 * Sweet Alert 2 component
 *
 * @augments {Component<Props, State>}
 */

// * Sweet Alert 2 - Toast Top
export const ToastTop = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

// * Sweet Alert 2 - Toast Bottom
export const ToastBottom = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});
