// 🌌 React
import React, { useState } from 'react';
// ⛽️ Bootstrap
import { Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/**
 * SidebarRight Component
 *
 * @augments {Component<Props, State>}
 */

const SidebarRight = ({ children, title }) => {
  // * Toggle Sidebar Right
  const [classes] = useState('sidebar-right-toggle dropdown');
  const toggleSidebarRight = () => {
    document.querySelector('.sidebar-right-toggle').classList.toggle('show');
  };

  return (
    <div className="sidebar-right">
      <div className={classes}>
        {/* <Button variant="link" className="m-0 p-0" onClick={() => toggleSidebarRight()}>
          <FontAwesomeIcon icon={['far', 'plus-hexagon']} size={'2x'} />
        </Button> */}
        <ul className="dropdown-menu show">
          <h4 className="mb-0 text-center align-self-center">{title}</h4>
          <Button variant="link" className="close m-0 p-0" onClick={() => toggleSidebarRight()}>
            <FontAwesomeIcon icon={['fal', 'times']} size={'2x'} />
          </Button>
          <li className="adjustments-line">{children}</li>
        </ul>
      </div>
    </div>
  );
};

export default SidebarRight;
