// 🔌 Axios
import axios from 'axios';
// 🍪 Cookies
import Cookies from 'js-cookie';
// 🔨 Const Utils
import { handleError } from './handleRequestError';
import { API_URL, TABLE, EDITPROFILE, OPTCATEGORY, USER } from './const';
import { movePlayer } from '../components/Game/Game';

// Web sockets
//import socketIOClient from "socket.io-client";

/**
 * GET, POST, UPDATE, DELETE
 *
 * @augments {Component<Props>}
 */

let token = Cookies.get('SID');
let usrID = Cookies.get('ID');

var wsUri = 'wss://app.doc.ba/game/update';
let websocket;

export function testWebSocket(userId, position, direction) {
  websocket = new WebSocket(wsUri);

  websocket.onopen = function () {
    onOpen(userId, position, direction);
  };
  websocket.onclose = function (evt) {
    onClose(evt);
  };
  websocket.onmessage = function (evt) {
    onMessage(evt);
  };
  websocket.onerror = function (evt) {
    onError(evt);
  };
}

function onOpen(userId, position, direction) {
  if (userId) {
    const payload = {
      usr_id: parseInt(userId),
      x: position.x,
      y: position.y,
      direction: direction,
    };

    newSend(JSON.stringify(payload));
  }
}

function onClose(evt) {
  console.log('DISCONNECTED: ', evt.data);
}

function onMessage(evt) {
  if (usrID) {
    movePlayer(evt.data);
  }
}

function onError(evt) {
  console.log('Error: ' + evt.data);
}

function newSend(message, callback) {
  waitForConnection(function () {
    websocket.send(message);
    if (typeof callback !== 'undefined') {
      callback();
    }
  }, 200);
}

function waitForConnection(callback, interval) {
  if (websocket.readyState === 1) {
    callback();
  } else {
    // optional: implement backoff for interval here
    setTimeout(function () {
      waitForConnection(callback, interval);
    }, interval);
  }
}

// GET GAME DATA

export async function getMeetingId() {
  return await axios({
    method: 'GET',
    url: 'https://app.doc.ba/api/table/options/?where=opt_name="meetingId"&columns=opt_value',
    headers: {
      'Content-Type': 'application/json',
      //'X-MARS-SID': `${token}`,
    },
  })
    .then((response) => {
      return response.data.data[0].opt_value;
    })
    .catch((err) => {
      return handleError(err.response);
    });
}

export async function getMeetingPassword() {
  return await axios({
    method: 'GET',
    url: 'https://app.doc.ba/api/table/options/?where=opt_name="meetingPassword"&columns=opt_value',
    headers: {
      'Content-Type': 'application/json',
      //'X-MARS-SID': `${token}`,
    },
  })
    .then((response) => {
      return response.data.data[0].opt_value;
    })
    .catch((err) => {
      return handleError(err.response);
    });
}

// * GET data for Options by parameter name (optCategory)
export async function getOption(optCategory) {
  return await axios({
    method: 'GET',
    url: `${API_URL}${OPTCATEGORY}${optCategory}`,
    headers: {
      'Content-Type': 'application/json',
      //'X-MARS-SID': `${token}`,
    },
  })
    .then((response) => {
      return response.data.mars;
    })
    .catch((err) => {
      return handleError(err.response);
    });
}

// * GET data for Table by parameter name of (table)
export async function getTable(table) {
  return await axios({
    method: 'GET',
    url: `${API_URL}${TABLE}${table}`,
    'X-MARS-SID': `${token}`,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return handleError(err.response);
    });
}

// * GET data for Table by parameter name of (table) and pagination sorting filter
export async function getTableP(table, start, limit, sorting, where) {
  if (where !== 1) {
    where = `item_name%20LIKE%20%27__${where}__%27`;
  }
  let { key, order } = sorting;
  return await axios({
    method: 'GET',
    url: `${API_URL}${TABLE}${table}?start=${start}&limit=${limit}&$key=${key}&order=${order}&where=${where}`, //${API_URL}${TABLE}?start=${start}&limit=${limit}&$key=${key}&order=${order},
    'X-MARS-SID': `${token}`,
  })
    .then((response) => {
      if (Array.isArray(response.data.data)) {
        return response.data.data;
      } else {
        return [];
      }
    })
    .catch((err) => {
      return handleError(err.response);
    });
}

// * GET Total Size (count)
export async function getTotalSize(table, where) {
  let url = `${API_URL}${TABLE}${table}/totalSize`;
  if (where !== 1) {
    where = `item_name%20LIKE%20%27__${where}__%27`;
    url = `${API_URL}${TABLE}${table}/totalSize?where=${where}`;
  }
  return await axios({
    method: 'GET',
    url: url, // https://app.doc.ba/api/table/:table/totalSize
    // 'X-MARS-SID': `${token}`,
  })
    .then((response) => {
      return response.data.totalSize;
    })
    .catch((err) => {
      return handleError(err.response);
    });
}

// * GET data for user by id
export async function getUser(userId) {
  return await axios({
    method: 'GET',
    url: `${API_URL}${USER}${userId}`,
    'X-MARS-SID': `${token}`,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return handleError(err.response);
    });
}

// * GET data for user by id
export async function getUserFullname(userId) {
  return await axios({
    method: 'GET',
    url: `${API_URL}${USER}${userId}?attr=usr_fullname`,
    'X-MARS-SID': `${token}`,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return handleError(err.response);
    });
}

// * EDIT User By Id
export async function onEditUser(dataEdit) {
  return await axios({
    method: 'PATCH',
    url: `${API_URL}${EDITPROFILE}`,
    data: JSON.stringify(dataEdit),
    headers: {
      'X-MARS-SID': `${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return handleError(err.response);
    });
}
