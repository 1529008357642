// 🌌 React
import React, { useState, useEffect, useRef } from 'react';
// 🚧 React router
import { Route, Switch } from 'react-router-dom';
// ⛽️ Bootstrap
import { Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 💬 Notifications
import NotificationAlert from 'react-notification-alert';
// 📦 Components
import Footer from '../components/Footer/Footer';
import Sidebar from '../components/Sidebar/Sidebar';
import FixedPlugin from '../components/FixedPlugin/FixedPlugin';
import NavbarDoc from '../components/Navbars/NavbarDoc';
// 📀 Routes data
import routes from '../routes.js';

/**
 * Admin Layout
 *
 * @augments {Component<Props, State>}
 */

const Admin = (props) => {
  const mainPanel = useRef();
  const nAlert = useRef(null);

  const [activeColor, setActiveColor] = useState('blue');
  const [sidebarMini, setSidebarMini] = useState(true);
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const showNavbarButton = () => {
    let up = 40;
    let down = 50;
    if (document.documentElement.scrollTop > up || document.scrollingElement.scrollTop > up || mainPanel.current.scrollTop > up) {
      setOpacity(1);
    } else if (document.documentElement.scrollTop <= down || document.scrollingElement.scrollTop <= down || mainPanel.current.scrollTop <= down) {
      setOpacity(0);
    }
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = (routes) => {
    let activeRoute = 'Default';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const handleActiveClick = (color) => setActiveColor(color);

  const handleMiniClick = () => {
    let notifyMessage = 'Sidebar mini ';
    if (document.body.classList.contains('sidebar-mini')) {
      setSidebarMini(false);
      notifyMessage += 'deactivated...';
    } else {
      setSidebarMini(true);
      notifyMessage += 'activated...';
    }
    let options = {};
    options = {
      place: 'bl',
      message: notifyMessage,
      type: 'info',
      icon: 'fal fa-bell',
      autoDismiss: 2,
    };
    nAlert.current.notificationAlert(options);
    document.body.classList.toggle('sidebar-mini');
  };

  const toggleSidebar = () => {
    setSidebarOpened(!sidebarOpened);
    document.documentElement.classList.toggle('nav-open');
  };

  const closeSidebar = () => {
    setSidebarOpened(false);
    document.documentElement.classList.remove('nav-open');
  };

  useEffect(() => {
    // * effect
    window.addEventListener('scroll', showNavbarButton);
    return () => {
      // * cleanup
      window.removeEventListener('scroll', showNavbarButton);
    };
  }, []);

  return (
    <div className="wrapper">
      <div className="rna-container">
        <NotificationAlert ref={nAlert} />
      </div>
      <div className="navbar-minimize-fixed" style={{ opacity: opacity }}>
        <Button variant="link" className="minimize-sidebar" onClick={handleMiniClick}>
          <span className="visible-on-sidebar-regular">
            <FontAwesomeIcon icon={['fad', 'list-alt']} size="2x" fixedWidth />
          </span>
          <span className="visible-on-sidebar-mini">
            <FontAwesomeIcon icon={['fal', 'ellipsis-v-alt']} size="2x" fixedWidth />
          </span>
        </Button>
      </div>
      <Sidebar {...props} routes={routes} activeColor={activeColor} closeSidebar={closeSidebar} />
      <div className="main-panel" ref={mainPanel} data={activeColor}>
        <NavbarDoc
          {...props}
          brandText={getActiveRoute(routes)}
          handleMiniClick={handleMiniClick}
          sidebarOpened={sidebarOpened}
          toggleSidebar={toggleSidebar}
        />
        <Switch>
          {getRoutes(routes)}
          {/*  <Redirect from="/" to="/admin/dashboard" /> */}
        </Switch>
        {
          // If we don't want the Footer to be rendered on "somepage" pages
          props.location.pathname.indexOf('somepage') !== -1 ? null : <Footer fluid />
        }
      </div>
      <FixedPlugin {...props} activeColor={activeColor} sidebarMini={sidebarMini} handleActiveClick={handleActiveClick} handleMiniClick={handleMiniClick} />
    </div>
  );
};

export default Admin;
