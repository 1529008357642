// 🌌 React
import React, { useState, useEffect } from 'react';
// ⛽️ Bootstrap
import { Button } from 'react-bootstrap';
// 🎯️ Font Awesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// 🚦 Switch buttons
import Switch from 'react-bootstrap-switch';

/**
 * FixedPlugin Component
 *
 * @augments {Component<Props, State>}
 */

const FixedPlugin = (props) => {
  const [classes, setClasses] = useState('dropdown');
  const [darkMode, setDarkMode] = useState(true);

  const [classNone, setClassNone] = useState('d-block');
  useEffect(() => {
    let er = props.location.pathname;
    if (er.indexOf('/login') !== -1 || er.indexOf('/register') !== -1) {
      setClassNone('d-none');
    } else {
      setClassNone('d-block');
    }
  }, [props.location.pathname]);

  const handleClick = () => {
    if (classes === 'dropdown') {
      setClasses('dropdown show');
    } else {
      setClasses('dropdown');
    }
  };

  const handleActiveMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('white-content');
  };

  return (
    <div className="fixed-plugin">
      <div className={classes}>
        <Button variant="link" className="m-0 p-0" onClick={() => handleClick()}>
          <FontAwesomeIcon icon={['far', 'cog']} size={'2x'} />
        </Button>
        <ul className="dropdown-menu show">
          <li className={`header-title ${classNone} mt-2`}>SIDEBAR BACKGROUND</li>
          <li className={`adjustments-line ${classNone}`}>
            <div className="badge-colors text-center">
              <span
                className={props.activeColor === 'primary' ? 'badge filter badge-primary active' : 'badge filter badge-primary'}
                data-color="primary"
                onClick={() => {
                  props.handleActiveClick('primary');
                }}
              />
              <span
                className={props.activeColor === 'blue' ? 'badge filter badge-info active' : 'badge filter badge-info'}
                data-color="info"
                onClick={() => {
                  props.handleActiveClick('blue');
                }}
              />
              <span
                className={props.activeColor === 'green' ? 'badge filter badge-success active' : 'badge filter badge-success'}
                data-color="success"
                onClick={() => {
                  props.handleActiveClick('green');
                }}
              />
              <span
                className={props.activeColor === 'orange' ? 'badge filter badge-warning active' : 'badge filter badge-warning'}
                data-color="warning"
                onClick={() => {
                  props.handleActiveClick('orange');
                }}
              />
              <span
                className={props.activeColor === 'red' ? 'badge filter badge-danger active' : 'badge filter badge-danger'}
                data-color="danger"
                onClick={() => {
                  props.handleActiveClick('red');
                }}
              />
            </div>
          </li>
          <li className={`header-title ${classNone}`}>SIDEBAR MINI</li>
          <li className={`adjustments-line ${classNone}`}>
            <div className="togglebutton switch-sidebar-mini">
              <Switch onChange={props.handleMiniClick} value={props.sidebarMini} onText="On" offText="Off" />
            </div>
          </li>
          <li className="header-title mt-3">THEMES COLOR</li>
          <li className="adjustments-line mb-2">
            <div className="togglebutton switch-change-color">
              <Switch onChange={handleActiveMode} value={darkMode} onText="Dark" offText="Light" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FixedPlugin;
